<template>
  <div class="product-article">
    <img :src="data.image || require(`@/assets/img/${data.image}`)" alt="product-image">
    <h2>{{ data.title }}</h2>
    <p v-html="data.short_description"></p>
    <router-link :to="'/blog/' + data.blog_id">Читать далее</router-link>
    <div class="product-article__tags">
      <div class="tag" v-for="(tag, i) in data.tags" :key="i">
        {{ tag && tag }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
}
</script>

<style scoped>
h2 {
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
}

.product-article {
  max-width: 330px;
  border: 1px solid #75B051;
  padding: 20px;
  max-height: 725px;
  display: flex;
  align-self: stretch;
}

.product-article img {
  max-width: 100%;
}
.product-article h2 {
  padding: 40px 0 15px 0;
}
.product-article p {
  margin-bottom: 40px;
}
.product-article a:hover {
  color: #75B051;
}

.product-article__tags {
  display: flex;
  /* justify-content: center; */
  column-gap: 10px;
  margin-top: 30px;
}
.product-article .tag {
  padding: 10px 20px;
  border: 1px solid #75B051;
  border-radius: 30px;
  margin: 10px 0 10px 10px;
}
</style>